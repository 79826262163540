<template>
    <div class="app-container">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" clearable filterable @change="handleInstitution"
                        placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                            :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                        class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                            :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="评审日期:">
                    <el-date-picker v-model="search.time" size='medium' type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="专家:">
                    <el-input placeholder="请输入专家姓名" v-model="search.expertName" size='medium' class="form-line-item">
                    </el-input>
                </el-form-item>
                <!-- <el-form-item label="状态:">
          <el-select v-model="search.statue" size='medium' clearable placeholder="请选择费用状态" class="form-line-item">
            <el-option label="已打款" value="1"></el-option>
            <el-option label="未打款" value="0"></el-option>
          </el-select>
        </el-form-item> -->
                <el-form-item label="题目:">
                    <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                        class="form-line-item">
                    </el-input>
                </el-form-item>
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="SearchBillHandle">
                        搜索
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>

                    <el-button type="success" size="small" icon="el-icon-download" @click="exportTable">导出财务表
                    </el-button>
                    <el-button type="success" size="small" icon="el-icon-download" @click="importBillHandle">打款
                    </el-button>
<!--                    <el-button type="danger" size="small" icon="el-icon-download" @click="paymentFailedHandle">打款失败-->
<!--                    </el-button>-->
                    <!-- <el-button type="success" size="small" icon="el-icon-download" @click="exportHandle">导出表2
                    </el-button>
                    </el-button>
                    <el-button type="success" size="small" icon="el-icon-download" @click="exportHandle">导出表3
                    </el-button>
                    </el-button>
                    <el-button type="success" size="small" icon="el-icon-download" @click="exportHandle">导出表4
                    </el-button> -->
                    <!-- <el-button type="success" size="small" @click="printHandle">打印</el-button>
                    </el-button> -->
                </template>
                <template #tools>

                    <el-popconfirm title="确认将所选内容设置为打款失败？"  @confirm="paymentFailedHandle">
                        <el-button slot="reference" style="margin-right: 5px" type="danger" size="small" icon="el-icon-download" >打款失败
                        </el-button>
                    </el-popconfirm>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                highlight-hover-row align="center" ref="paperTable" :data="billList"
                class="mytable-scrollbar financialList" show-footer
                :footer-method="footerMethod" :merge-footer-items="mergeFooterItems"
                :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                :loading='loading'>
                <vxe-column type='seq' title="序号" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='checkbox' title="全选" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperName" title="论文题目" width="30%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位" width="15%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperType" title="论文类型" width="13%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertName" title="专家姓名" width="8%" :show-overflow="'tooltip'" sortable>
                </vxe-column>
                <vxe-column field="reviewTime" title="评审时间" width="8%" :show-overflow="'tooltip'" sortable>
                </vxe-column>
                <vxe-column field="reward" title="评审费用" width="8%" :show-overflow="'tooltip'" sortable></vxe-column>
                <vxe-column field="payStatue" title="费用状态" width="8%" :show-overflow="'tooltip'">
                    <template #default='{row}'>
                        {{payStatue[row.payStatue].text}}
                        <!-- <span>{{(row.payErrorInfo != ''?innerPayStatue[row.payErrorInfo]:payStatue[row.payStatue].text)}}</span> -->
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 上传打款表 -->
        <vxe-modal v-model="uploadModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                上传打款表
            </template>
            <el-upload ref='templateFile' class="upload-demo" drag action="#" :auto-upload='false'>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
            <template #footer>
                <el-button @click="cfmImportBillHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
        <!-- 打款弹窗 -->
        <el-dialog :visible.sync="sendBillDiaVis" :close-on-click-modal="false" width="60%" class='dialog'>
            <template slot="title">
                <div class='titleDia'>
                    账单详情
                </div>
            </template>
            <div>
                <vxe-table :header-cell-style="headerCellStyle" border stripe round highlight-hover-row align="center"
                    ref="sendBillTable" :data="sendBillList" show-footer :footer-method="footerMethod">
                    <vxe-column field="expertName" title="专家姓名" width="10%"></vxe-column>
                    <vxe-column field="payType" title="支付方式" width="15%"></vxe-column>
                    <vxe-column field="account" title="支付账号" width="20%"></vxe-column>
                    <vxe-column field="bankName" title="开户人" width="10%"></vxe-column>
                    <vxe-column field="paperNum" title="论文数量（篇）" width="15%"></vxe-column>
                    <vxe-column field="totalFee" title="总评审费（元）" width="15%"></vxe-column>
                    <vxe-column field="statue" title="状态" width="15%">
                        <template #default={row}>
                            <div :class="row.statue == '200'?'green':'red'">
                                {{innerPayStatue[row.statue]}}
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
                <div style="padding:10px;">
                    <label for="yzm">打款验证码：</label>
                    <el-input v-model="yzm" label='yzm' style="width:150px;" size='small'></el-input>
                    <el-button type="primary" plain class="yzmBtn" :disabled='!showBtn' size="small" @click="getCode">
                        {{showBtn?'点击获取验证码':second+'秒后重新获取'}}
                    </el-button>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="sendBillDiaVis = false" size="small">取 消</el-button>
                <el-button type="primary" @click="confirmSendBill" size="small" :disabled='sending'>打 款</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    export default {
        name: 'unpay',
        data() {
            return {
                uploadModel: false,
                loading: false,
                payStatue: {
                    1: {
                        text: '已支付',
                        color: 'green'
                    },
                    0: {
                        text: '待支付',
                        color: 'red'
                    }
                },
                AlipayBalance: '10000000',
                WeChatBalance: '1000000',
                batchShow: true,
                institutionList: [],
                batchList: [],
                search: {
                    institutionId: '',
                    expertName: '',
                    batchId: '',
                    title: '',
                    statue: '',
                    time: ['', '']
                },
                // 分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [10, 20, 50, 100, 200],
                    total: 0
                },
                // 账单列表
                billList: [
                    // {
                    //     paperId: '1',
                    //     paperName: '基于CNN的图像分类',
                    //     paperType: '专业论文',
                    //     reward: 200,
                    //     expertId: 10,
                    //     expertName: '史唅',
                    //     reviewTime: '2020-12-30',
                    //     payType: '',
                    //     payStatue: '0',
                    //     reviewId: '1',
                    //     isRe: 0
                    // },
                    // {
                    //     paperId: '1',
                    //     paperName: '基于CNN的图像分类',
                    //     paperType: '专业论文',
                    //     reward: 200,
                    //     expertId: 11,
                    //     expertName: '张鹏',
                    //     reviewTime: '2020-12-30',
                    //     payType: '',
                    //     payStatue: '0',
                    //     reviewId: '2',
                    //     isRe: 1
                    // }
                ],
                //账单详情对话框
                sendBillDiaVis: false,
                sendBillList: [],
                footerData: [
                    ['合计', 0, 0]
                ],
                sendBill: {},
                innerPayStatue: {
                    '200': '已支付',
                    '201': '待支付',
                    '5001': '单笔额度超限',
                    '5002': '账户余额不足',
                    '5003': '账号信息错误',
                    '5004': '日累计额度超限',
                    '5005': '其他未知错误'
                },
                sending: false,
                showBtn: true,
                second: 0,
                timer: null,
                yzm: '',
                pageloading: '',
                mergeFooterItems: [{
                    row: 0,
                    col: 0,
                    rowspan: 0,
                    colspan: 0
                }],
            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.getInstitution();
            this.getPaperList();

        },
        methods: {
            handleKeyPress(event) {
                if (event.keyCode === 13) {
                    this.getPaperList();
                }
            },

            //表格刷新
            refresh() {
                this.getPaperList();
            },
            //获取送审单位
            getInstitution() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20,
                    isFiled:0
                }
                console.log(params);
                this.$api.schoolCustom.getSendingInstitution(params)
                    .then(res => {
                        console.log(123);
                        this.institutionList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId);
                // param.append("isFiled",0);
                this.$api.sendingP2p.getBatch(param)
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                this.search.batchId = '';
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            getPaperList() {
                this.loading = true;
                let param = new URLSearchParams();
                if(this.search.time == null){
                    this.search.time = ['','']
                }
                param.append("institutionId", this.search.institutionId);
                param.append("batchId", this.search.batchId);
                param.append("payStatue", 0);
                param.append("title", this.search.title);
                param.append("expertName", this.search.expertName);
                param.append("startTime", this.search.time[0] ? this.dateFormat(this.search.time[0]) : '');
                param.append("endTime", this.search.time[1] ? this.dateFormat(this.search.time[1]) : '');
                param.append("pageIndex", this.page.currentPage);
                param.append("pageSize", this.page.pageSize);
                param.append("isRewardFailed", 0);
                this.$api.financial.getBills(param)
                    .then(res => {
                        console.log(res.data);
                        this.billList = res.data.data;
                        this.page.total = res.data.count;
                        this.loading = false;
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    });
            },
            //搜索打款记录
            SearchBillHandle() {
                this.getPaperList();
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    expertName: '',
                    batchId: '',
                    title: '',
                    statue: '',
                    time: ['', '']
                }
            },
            getFileName(str) {
                return str.substr(str.indexOf('\\') + 1)
            },
            // 下载zip
            getZip(path) {

                this.$api.reviewCheck.getZip({
                    path: path
                }).then(res => {
                    console.log(res);
                    let data = res.data;
                    let str = res.headers['content-disposition'].split(';')[1].split('=')[1];
                    let filename = "";
                    for (let i = 1; i < str.length - 1; i++) filename += str[i];
                    let blob = new Blob([data], {
                        type: 'application/octet-stream;charset=utf-8'
                    });
                    let url = window.url || window.webkitURL;
                    let href = url.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = href;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(href);
                    this.loading.close();
                }).catch(err => {
                    this.$message.warning("导出格式有误");
                })
            },
            // 导出财务表
            exportTable() {
                let seleted = this.$refs.paperTable.getCheckboxRecords();
                if (seleted.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                this.loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let arr = [];
                seleted.forEach(item => {
                    arr.push(item.reviewId);
                });
                // let params = new FormData();
                // params.append('reviewIds',arr);
                let params = {
                    reviewIds: arr
                };
                this.$api.financial.postTables(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            // 下载第1个
                            let path = res.data.data;
                            console.log(res)
                            this.getZip(path);
                            // this.$api.financial.getTables({
                            //         path: path[0]
                            //     })
                            //     .then(res => {
                            //         const blob = new Blob([res.data], {
                            //             type: 'Application/vnd.ms-excel;charset=utf-8'
                            //         });
                            //         let url = window.URL || window.webkitURL;
                            //         const href = url.createObjectURL(blob);
                            //         let downloadElement = document.createElement('a');
                            //         downloadElement.href = href;
                            //         downloadElement.download = this.getFileName(path[0]); //下载后文件名
                            //         document.body.appendChild(downloadElement);
                            //         downloadElement.click(); //点击下载
                            //         document.body.removeChild(downloadElement); //下载完成移除元素
                            //         window.URL.revokeObjectURL(href); //释放掉blob对象
                            //     }).catch(err => {
                            //         this.$message.warning("导出表1错误");
                            //     })
                            // // 下载第2个
                            // this.$api.financial.getTables({
                            //         path: res.data.data[1]
                            //     })
                            //     .then(res => {
                            //         const blob = new Blob([res.data], {
                            //             type: 'Application/vnd.ms-excel;charset=utf-8'
                            //         });
                            //         let url = window.URL || window.webkitURL;
                            //         const href = url.createObjectURL(blob);
                            //         let downloadElement = document.createElement('a');
                            //         downloadElement.href = href;
                            //         downloadElement.download = this.getFileName(path[1]);; //下载后文件名
                            //         document.body.appendChild(downloadElement);
                            //         downloadElement.click(); //点击下载
                            //         document.body.removeChild(downloadElement); //下载完成移除元素
                            //         window.URL.revokeObjectURL(href); //释放掉blob对象
                            //     }).catch(err => {
                            //         this.$message.warning("导出表2错误");
                            //     })
                            // // 下载第3个
                            // this.$api.financial.getTables({
                            //         path: res.data.data[2]
                            //     })
                            //     .then(res => {
                            //         const blob = new Blob([res.data], {
                            //             type: 'Application/vnd.ms-excel;charset=utf-8'
                            //         });
                            //         let url = window.URL || window.webkitURL;
                            //         const href = url.createObjectURL(blob);
                            //         let downloadElement = document.createElement('a');
                            //         downloadElement.href = href;
                            //         downloadElement.download = this.getFileName(path[2]);; //下载后文件名
                            //         document.body.appendChild(downloadElement);
                            //         downloadElement.click(); //点击下载
                            //         document.body.removeChild(downloadElement); //下载完成移除元素
                            //         window.URL.revokeObjectURL(href); //释放掉blob对象
                            //     }).catch(err => {
                            //         this.$message.warning("导出表3错误");
                            //     })
                            // // 下载第4个
                            // this.$api.financial.getTables({
                            //         path: res.data.data[3]
                            //     })
                            //     .then(res => {
                            //         const blob = new Blob([res.data], {
                            //             type: 'Application/vnd.ms-excel;charset=utf-8'
                            //         });
                            //         let url = window.URL || window.webkitURL;
                            //         const href = url.createObjectURL(blob);
                            //         let downloadElement = document.createElement('a');
                            //         downloadElement.href = href;
                            //         downloadElement.download = this.getFileName(path[3]);; //下载后文件名
                            //         document.body.appendChild(downloadElement);
                            //         downloadElement.click(); //点击下载
                            //         document.body.removeChild(downloadElement); //下载完成移除元素
                            //         window.URL.revokeObjectURL(href); //释放掉blob对象
                            //         loading.close();
                            //     }).catch(err => {
                            //         this.$message.warning("导出表4错误");
                            //     })
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                    })
            },
            importBillHandle() {
                this.uploadModel = true;
            },

            // 导入打款表
            cfmImportBillHandle() {
                if (this.$refs.templateFile.uploadFiles.length < 1) {
                    this.$message.warning("请选择模板文件");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let rawFile = this.$refs.templateFile.uploadFiles[0];
                console.log(rawFile.raw);
                var reader = new FileReader(); //读取文件内容
                reader.readAsArrayBuffer(rawFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
                reader.onload = (e) => {
                    var dd = e.target.result;
                    var workbook = XLSX.read(dd, {
                        type: 'buffer'
                    });
                    var sheetname = workbook.SheetNames[0]

                    var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
                    var billList = [];
                    for (let i in tables) {
                        let row = tables[i];
                        console.log(row);
                        var paps = {
                            "isReward": row["是否支付"] ? row[
                                "是否支付"] : '0',
                            "expertName": row["姓名"] ? row[
                                '姓名'] : '',
                            "idNum": typeof (row["证件号码"]) == 'undefined' ? '' : row[
                                "证件号码"],
                            "bankCardNum": typeof (row["卡号（或账号）"]) == 'undefined' ? '' : row[
                                "卡号（或账号）"],
                            "reward": typeof (row["收入（税前）"]) == 'undefined' ? '' : row[
                                "收入（税前）"],
                            "taxedReward": typeof (row['收入（税前）']) == 'undefined' ? '' : row['收入（税前）'],
                            "reviewIds": typeof (row['评阅书id']) == 'undefined' ? '' : row[
                                '评阅书id'],
                        };
                        billList.push(paps);
                    };
                    console.log(billList);
                    // let params = new FormData();
                    // params.append("PayFeedbackList", billList);
                    let params = {
                        "payFeedbackList": billList
                    }
                    this.$api.financial.postCheckPay(params)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("付款成功！");
                                this.uploadModel = false;
                                loading.close();
                            }
                        }).catch(err => {
                            this.$message.warning("请求出错！");
                        })
                }

                // this.$api.reviewCustom.postUploadPatternFile(params)
                //     .then(res => {
                //         loading.close();
                //         if (res.data.code == 200) {
                //             this.$message.success("上传成功！");
                //             this.uploadModel = false;
                //         } else {
                //             this.$message.warning("上传失败！");
                //         }

                //     })
                //     .catch(err => {});
            },
            //打款请求
            sendBillHandle() {
                this.sending = false;
                let seleted = this.$refs.paperTable.getCheckboxRecords();
                if (seleted.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                let bills = {};
                let tSendBill = {
                    reviewIds: [],
                    isRes: []
                };

                seleted.forEach(item => {
                    tSendBill.reviewIds.push(item.reviewId);
                    tSendBill.isRes.push(item.isRe);
                    if (bills.hasOwnProperty(item.expertId)) {
                        bills[item.expertId].paperNum += 1;
                        bills[item.expertId].totalFee += item.reward;
                    } else {
                        bills[item.expertId] = {
                            expertName: item.expertName,
                            paperNum: 1,
                            totalFee: item.reward
                        }
                    }
                });
                let cbills = [];
                let paperNum = 0,
                    totalFee = 0;
                let expertIds = [];
                for (var key in bills) {
                    expertIds.push(key);
                    // cbills.push({
                    //     expertName: bills[key].expertName,
                    //     paperNum: bills[key].paperNum,
                    //     totalFee: bills[key].totalFee
                    // });
                    paperNum += bills[key].paperNum;
                    totalFee += bills[key].totalFee;
                }
                this.footerData = [
                    ['合计', '-', '-', '-', paperNum, totalFee]
                ];
                let payTypeMap = ['支付宝', '微信', '银行卡'];

                this.sendBill = tSendBill;
                let param = new URLSearchParams();
                param.append("expertIds", expertIds);
                this.$axios.get("/finance/get_expert_pay_info", {
                    params: param
                }).then(res => {
                    console.log(res);
                    res.data.data.forEach(item => {
                        let key = item.expertId;
                        cbills.push({
                            expertId: key,
                            expertName: bills[key].expertName,
                            bankName: item.bankName,
                            payType: payTypeMap[item.payType],
                            account: item.account,
                            paperNum: bills[key].paperNum,
                            totalFee: bills[key].totalFee,
                            statue: '201'
                        });
                    })
                    this.sendBillList = cbills;
                    this.sendBillDiaVis = true;
                }).catch(err => {
                    this.$message.warning("服务器维护！");
                })
            },
            //获取验证码
            getCode() {
                if (!this.timer) {
                    this.second = 60;
                    this.showBtn = false;
                    this.$axios.get('/sms/send_pay_verification_code').then(res => {
                            if (res.data.data == 0) {
                                this.$message.warning("获取失败!");
                            }
                        })
                        .catch(err => {
                            this.$message.warning("服务器维护!");
                        })
                    this.timer = setInterval(() => {
                        console.log(this.second);
                        if (this.second > 0) {
                            this.second--;
                        } else {
                            this.showBtn = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            //确认打款
            confirmSendBill() {
                if (this.yzm == '') {
                    this.$message.warning("请输入验证码！");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '打款中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let param = {
                    reviewIds: this.sendBill.reviewIds,
                    isRes: this.sendBill.isRes,
                    code: this.yzm
                };
                console.log(param);
                this.$axios.post('/finance/transfer', param)
                    .then(res => {
                        loading.close();
                        this.sending = true;
                        // this.sendBillDiaVis = false;
                        if (res.data.code == 200) {
                            // this.$message.success("打款成功！");
                            res.data.data.forEach(item => {
                                for (let i = 0; i < this.sendBillList.length; i++) {
                                    console.log(this.sendBillList[i].expertId, item.expertId);
                                    if (this.sendBillList[i].expertId == item.expertId) {
                                        this.sendBillList[i].statue = item.errorCode;
                                    }
                                }
                            })
                            this.getPaperList();
                        } else {
                            this.$message.warning(this.data.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            //导出
            exportHandle() {
                this.$refs.paperTable.openExport()
            },
            //打印
            printHandle() {
                this.$refs.paperTable.openPrint()
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList();
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList();
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList();
            },
            //表尾数据
            //表尾数据
            footerMethod({
                columns,
                data
            }) {
                const sums = [];
                let total = 0;
                data.forEach(item => {
                    console.log(item.reward);
                    total += parseFloat(item.reward);
                })
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('总计')
                    } else {
                        switch (column.property) {
                            case 'reward':
                                sums.push(total);
                                break
                            default:
                                sums.push('-');
                        }
                    }
                })
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            //头部样式
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            //设置单元格样式
            cellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                //设置状态颜色
                let styles = {};
                if (column.property == 'payStatue') {
                    styles['color'] = this.payStatue[row.payStatue].color;
                }
                return styles;
            },
            dateFormat: function (time) {
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime
                    .getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
            isShowSelectOptions(isShowSelectOptions){
                if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
                if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
            },
            paymentFailedHandle(){
                // 判断选择的论文数量是否为0
                let selected = this.$refs.paperTable.getCheckboxRecords();
                if (selected.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                // 二次确认

                // 将所有的id进行提取
                let reviewIds = [];
                for(let item of selected){
                    reviewIds.push(item.reviewId)
                }
                let params = {
                    reviewIds:reviewIds
                }
                // console.log(reviewIds)
                const loading = this.$loading({
                    lock: true,
                    text: '更新中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.financial.setIsRewardFailed(reviewIds).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success("更新打款信息！");
                        this.getPaperList();
                        loading.close();
                    }
                }).catch(err => {
                    this.$message.warning("请求出错！");
                    loading.close();
                });
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .form-line-item {
        width: 15em;
    }

    .green {
        color: green;
    }

    .red {
        color: red;
    }

    .el-dialog__body {
        padding-bottom: 0px;
    }

    .yzmBtn {
        margin-left: 5px;
    }
    .el-form-item {
        margin-bottom: 10px !important;
    }
</style>

<style>
    .financialList .vxe-table--body-wrapper {
        height: calc(100vh - 390px);
    }
</style>